import React from "react";
import { ListGroup } from 'react-bootstrap';

export default function Home() {

    const url = "http://221.154.163.203/download/"

    var enavFiles13 = [
      'apk/단말기 런처 (Ver 1.0.1).apk',
      'apk/Badagiljabi13_1.3.0.apk',
      'apk/arubanetworks-arubautilities-184.apk',
      'apk/enav_filedownloader_1.3.2.apk',
      'apk/7zipper-2-0-2-9-30.apk',
      'other/ecs_logo.png',
      'other/13/update.zip'
    ]
    var enavFiles10 = [
      'apk/BadaGiljabi_2.3.1.apk',
      'apk/Badagiljabi_Recovery.apk',
      'apk/enav_filedownloader_1.3.2.apk',
      'apk/BadaGiljabiLauncher_2.3.8_A_Ship.apk',
      'apk/iPerf.apk',
      'apk/7zipper-2-0-2-9-30.apk',
      'apk/BadaGiljabiLauncher_Factory.apk'
    ]

    const download13 = () => {
      var step = 0;
      for(step=0;step<enavFiles13.length;step++){
        setTimeout(() => {
            var fileUrl = enavFiles13.pop();
            var a = document.createElement("a");
    
            var filePath = fileUrl.split('/');
            var fileName = filePath[filePath.length - 1]
            a.setAttribute('href', url + fileUrl);
            a.setAttribute('download', fileName);
            a.setAttribute('target', '_blank');
            a.click();

            },step*3000);
      }
      return true;
    }

    const download10 = () => {
      var step = 0;
      for(step=0;step<enavFiles10.length;step++){
        setTimeout(() => {
            var path = enavFiles10.pop();
            var a = document.createElement("a");

            var filePath = path.split('/');
            var fileName = filePath[filePath.length - 1]
            a.setAttribute('href', url + path);
            a.setAttribute('download', fileName);
            a.setAttribute('target', '_blank');
            a.click();
          },step*3000);
    }
      return true;
      
    }
  return (
    <body>
        <ListGroup>
              <ListGroup.Item variant="success" style={{fontSize:30}} onClick={download13}>13인치 모든 세팅 앱 다운로드</ListGroup.Item>
              <ListGroup.Item variant="success" style={{fontSize:30}} onClick={download10}>10인치 모든 세팅 앱 다운로드</ListGroup.Item>
        </ListGroup>
    </body>

  );
}
